let mdColorBlue = "#00a5eb",
	backgroundAdded = false,
	// Canvas size
	newWidth = 1250,
	ratio = newWidth / 1250, //1250x694
	_canvas = {
		width: 1250,
		height: 694
	},
	// sizes
	_mainBox = {
		top: Math.round(480 * ratio),
		left: Math.round(50 * ratio),
		right: Math.round(150 * ratio),
		height: Math.round(88 * ratio)
	},
	_textFocim = {
		top: Math.round(498 * ratio),
		left: Math.round(61 * ratio),
		height: 56.5 * ratio,
		fontsize: 50 * ratio,
		fontweight: 300,
		fontfamily: "CamptonBold"
	},
	_textAlcim = {
		top: Math.round(585 * ratio),
		left: Math.round(57 * ratio),
		fontsize: 29 * ratio,
		fontfamily: "Open Sans",
		fontweight: 300
	},
	// colors
	workColor = mdColorBlue;

let canvas = new fabric.Canvas("mdCanvas", {
	backgroundColor: "#bbb",
	width: _canvas.width,
	height: _canvas.height,
	containerClassName: "mdCanvas",
	preserveObjectStacking: true,
	renderOnAddRemove: true,
	enableRetinaScaling: false,
	controlsAboveOverlay: true,
	selection: false
});

let ctx = canvas.getContext();

// set controls style
fabric.Object.prototype.set({
	transparentCorners: true,
	hasRotatingPoint: false,
	borderColor: "#ff00ff",
	cornerColor: "rgba(255, 0, 255, 0.454)",
	cornerSize: 8,
	cornerStyle: "circle",
	padding: 0
});

// update :contains() case sensivity
$.expr[":"].contains = $.expr.createPseudo(function(arg) {
	return function(elem) {
		return (
			$(elem)
				.text()
				.toUpperCase()
				.indexOf(arg.toUpperCase()) >= 0
		);
	};
});

// Alapértelmezett elemek
// ****************************************************** Főcím
let textFocim = new fabric.IText("FŐCÍM...", {
	left: _textFocim.left,
	top: _textFocim.top,
	// textBackgroundColor: "red",
	fontSize: _textFocim.fontsize,
	fontFamily: _textFocim.fontfamily,
	lineHeight: 1.2,
	fill: "#ffffff",
	fontWeight: _textFocim.fontweight,
	textAlign: "left",
	selectable: false,
	editable: false,
	hasControls: false
});

//.setActiveObject(textFocim);

// ****************************************************** Alcím
let textAlcim = new fabric.IText("Alcím...", {
	left: _textAlcim.left,
	top: _textAlcim.top,
	fontSize: _textAlcim.fontsize,
	fontFamily: _textAlcim.fontfamily,
	lineHeight: 1.2,
	fill: "#ffffff",
	fontWeight: _textAlcim.fontweight,
	textAlign: "left",
	selectable: false,
	editable: false,
	hasControls: false
});

// ****************************************************** SZövegbox

let mainBoxRect = new fabric.Rect({
	left: _mainBox.left,
	top: _mainBox.top,
	fill: workColor,
	height: _mainBox.height,
	width: Math.round(227 * ratio),
	opacity: 1,
	selectable: false,
	editable: false,
	hasControls: false
});

/***********************************************************************************
//----------------------------------------------------------------------------------  UNDO / REDO
*/

let state; // current unsaved state
let undo = []; // past states
let redo = []; // reverted states

//Push the current state into the undo stack and then capture the current state
function save() {
	// clear the redo stack
	redo = [];
	$("#redo").prop("disabled", true);

	// initial call won't have a state
	if (state) {
		undo.push(state);
		$("#undo").prop("disabled", false);
	}

	state = JSON.stringify(canvas);

	// console.log("state--->   ", state);
}

/**
 * Save the current state in the redo stack, reset to a state in the undo stack, and enable the buttons accordingly.
 * Or, do the opposite (redo vs. undo)
 * @param playStack which stack to get the last state from and to then render the canvas as
 * @param saveStack which stack to push current state into
 * @param buttonsOn jQuery selector. Enable these buttons.
 * @param buttonsOff jQuery selector. Disable these buttons.
 */
function replay(playStack, saveStack, buttonsOn, buttonsOff) {
	saveStack.push(state);
	state = playStack.pop();
	let on = $(buttonsOn);
	let off = $(buttonsOff);
	// turn both buttons off for the moment to prevent rapid clicking
	on.prop("disabled", true);
	off.prop("disabled", true);
	canvas.clear();

	canvas.loadFromJSON(state, function() {
		canvas.renderAll();
		// now turn the buttons back on if applicable
		on.prop("disabled", false);
		if (playStack.length) {
			off.prop("disabled", false);
		}
	});
}

// undo and redo buttons
$("button#undo").click(() => {
	replay(undo, redo, "#redo", this);
});
$("button#redo").click(() => {
	replay(redo, undo, "#undo", this);
});

// save initial state
save();

/***********************************************************************************
 //----------------------------------------------------------------------------------  CANVAS EVENTS
 */

// register event listener for user's actions
canvas.on("object:modified", function() {
	// console.log("called from here");
	save();
});

canvas.on("object:selected", function(event) {
	let selectedObject = event.target;

	// console.log(selectedObject);

	if (selectedObject.type !== "image") {
		// setWorkColor(selectedObject.get("fill"));
	}

	if (selectedObject.type == "i-text") {
		let activeFont = selectedObject.get("fontFamily");
		console.log(activeFont);
		// let activeFont = selectedObject.get("fontWeight");
		$('#betuTipus option[value="' + activeFont + '"]').prop(
			"selected",
			true
		);
		let activeFontSize = selectedObject.get("fontSize");
		$("#betuMeret").val(activeFontSize);
	}
});

/***********************************************************************************
//----------------------------------------------------------------------------------  KÉP MENTÉS GALÉRIÁBA
*/

$(document).on("click", "input[type=text]", function() {
	this.select();
});

$("button#savetogallery").click(event => {
	event.preventDefault();
	// document.getElementById("imgdata").value = canvas.toDataURL(
	// 	"image/png"
	// );
	// showLoaderWindow();
	canvas.discardActiveObject().renderAll();
	// console.log(canvas.getActiveObject());

	let d = new Date(),
		n = d.getTime(),
		fileName =
			"melodiak-" + n + "-" + _canvas.width + "x" + _canvas.height + "px";
	// console.log(n);
	let dataURL = canvas.toDataURL({ format: "jpeg", quality: 1 });

	// letöltés
	canvasToImage("mdCanvas", {
		name: fileName,
		type: "jpg",
		quality: 1
	});

	/*
	$.ajax({
		xhr: function() {
			var xhr = new window.XMLHttpRequest();
			//Upload progress
			xhr.upload.addEventListener(
				"progress",
				function(evt) {
					if (evt.lengthComputable) {
						var percentComplete = evt.loaded / evt.total;
						//Do something with upload progress
						console.log(percentComplete);
					}
				},
				false
			);
			//Download progress
			xhr.addEventListener(
				"progress",
				function(evt) {
					if (evt.lengthComputable) {
						var percentComplete = (evt.loaded / evt.total) * 100;
						//Do something with download progress
						console.log(percentComplete);
					}
				},
				false
			);
			return xhr;
		},
		type: "POST",
		url: "../../saveimg.php",
		// enctype: "multipart/form-data",
		data: {
			// imgdata: canvas.toDataURL("image/png", 1),
			// imgdata: canvas.toDataURL({ format: "jpeg", quality: 1 }),
			imgdata: dataURL,
			filename: fileName + ".jpg",
			description: $("#description").val(),
			category: $("#kategoria").val()
		},
		cache: false,
		success: function() {
			hideLoaderWindow();

			msgWin("Galériába sikeresen elmentve.", "success");

			// letöltés
			canvasToImage("mdCanvas", {
				name: fileName,
				type: "jpg",
				quality: 1
			});

			setTimeout(() => {
				// reset canvas
				canvas.clear();
				window.location = "/";
			}, 3000);
		},
		error: function(e) {
			console.log("ERROR : ", e.responseText);
		}
	});
	*/
});

/***********************************************************************************
//----------------------------------------------------------------------------------  BETŰTÍPIS
*/

// var fontMontserrat = new FontFaceObserver("Montserrat");
// var fontLato = new FontFaceObserver("Lato");
// fontMontserrat.load();
// fontLato.load();

$("#betuTipus").on("change", ({ currentTarget }) => {
	console.log($(currentTarget).val());
	let selectedFont = $(currentTarget).val();
	let selectedObject = canvas.getActiveObject();
	let fontWeight = 700;
	let fontSize = 50;
	if (selectedObject && selectedObject.type == "i-text") {
		if (selectedFont == "Open Sans") {
			fontWeight = 400;
			fontSize = 29;
		}

		selectedObject.set({
			fontFamily: selectedFont,
			fontWeight: fontWeight,
			fontSize: fontSize
		});
		canvas.renderAll();
		// loadAndUse("Montserrat", selectedObject, fontWeight);
		// canvas.renderAll();
		// save();
	}
});

$("#betuMeret").on("change", ({ currentTarget }) => {
	let selectedFontSize = $(currentTarget).val();
	let selectedObject = canvas.getActiveObject();
	if (selectedObject && selectedObject.type == "i-text") {
		selectedObject.set({
			fontSize: selectedFontSize
		});
		canvas.renderAll();
		save();
	}
});

$("#betuMeret").on("focus", ({ currentTarget }) => {
	$(currentTarget).blur();
});
/***********************************************************************************
//----------------------------------------------------------------------------------  RÉTEGRENDEZÉS GOMBOK
*/

$("button#tofront").click(() => {
	let selectedObject = canvas.getActiveObject();
	if (selectedObject) {
		canvas.bringToFront(selectedObject);
		canvas.renderAll();
		save();
		// canvas.discardActiveObject();
	}
});

$("button#forward").click(() => {
	let selectedObject = canvas.getActiveObject();
	if (selectedObject) {
		canvas.bringForward(selectedObject);
		canvas.renderAll();
		save();
		// canvas.discardActiveObject();
	}
});

$("button#toback").click(() => {
	let selectedObject = canvas.getActiveObject();
	if (selectedObject) {
		canvas.sendToBack(selectedObject);
		canvas.renderAll();
		save();
		// canvas.discardActiveObject();
	}
});

$("button#backward").click(() => {
	let selectedObject = canvas.getActiveObject();
	if (selectedObject) {
		canvas.sendBackwards(selectedObject);
		canvas.renderAll();
		save();
		// canvas.discardActiveObject();
	}
});

/***********************************************************************************
//----------------------------------------------------------------------------------  OBJEKTUM BESZÚRÁSOK
*/

// ****************************************************** KÉP
$("button#loadImage").click(() => {
	// console.log(canvas.item(0).type);
	// if (canvas.item(0).type == "image") {
	// 	// msgWin("Van már kép, törölni kell!", "error");
	// 	backgroundAdded = true;
	// }
	// $("#imgInput").click();
	scrollToSection("#galleryTitle");
});

$(".loadImageToCanvas").click(({ currentTarget }) => {
	// console.log(canvas.item(0).type);
	console.log($(currentTarget).data("image"));
	let _URL = window.URL || window.webkitURL;
	let image, newBgImage;

	if (canvas.item(0).type == "image") {
		canvas.item(0).remove();
	}

	// drawFree(this.src, this.height, this.width);
	newBgImage = fabric.Image.fromURL($(currentTarget).data("image"), function(
		img
	) {
		img.scale(1.0)
			.set({
				left: 0,
				top: 0,
				hascontrols: false,
				selectable: false
			})
			.setControlsVisibility({
				mt: false,
				mb: false,
				ml: false,
				mr: false
			});
		canvas
			.add(img)
			// .setActiveObject(img)
			.sendToBack(img);
		// fitToCanvas(img, "w");
	});
	scrollToSection("#editor");
});

/***********************************************************************************
//----------------------------------------------------------------------------------  SZÍN
*/

function setWorkColor(c) {
	workColor = c;
}

function setAlcimTextColor(c) {
	let a = c.replace("#", "");
	$("button#colorPicker").css({ backgroundColor: c });

	$(".colorswatch").removeClass("current");
	$("#c" + a).addClass("current");

	textAlcim.set({
		fill: c
	});
	canvas.renderAll();
}

let alcimTextColorPal = [
	["#ffffff", "Fehér"],
	["#000000", "Fekete"]
];

let colorTemplate =
	'<div data-col="{color}" style="background-color: {color}; color: {textcolor}" class="colorswatch" id="c{attr}"></div>';
// '<div data-col="{color}" style="background-color: {color}; color: {textcolor}" class="colorswatch" id="c{attr}">{kat}</div>';
let colorPalHtml = "<span>";
alcimTextColorPal.forEach(function(c, i) {
	let a = c[0].replace("#", "");
	let bgcolor = tinycolor(c[0]);
	// let textcolor = bgcolor.isDark() ? "#ccc" : "#ccc";
	let textcolor = bgcolor.toName() == "white" ? "black" : "white";
	colorPalHtml += colorTemplate
		.replace(/\{color\}/g, c[0])
		.replace(/\{kat\}/g, c[1])
		.replace(/\{textcolor\}/g, textcolor)
		.replace(/\{attr\}/g, a);
	if ((i + 1) % 1 == 0) {
		colorPalHtml += "</span>";
		colorPalHtml += "<span>";
	}
});
colorPalHtml += "</span>";
$("#color-picker-container").html(colorPalHtml);

$("button#colorPicker").click(() => {
	$("#color-picker-container").fadeIn(100);
	// $("#colorCustom").click();
});

$("#color-picker-container").mouseleave(() => {
	$("#color-picker-container").fadeOut(100);
});

$(".colorswatch").click(({ currentTarget }) => {
	setAlcimTextColor($(currentTarget).data("col"));
});

/***********************************************************************************
//----------------------------------------------------------------------------------  ÜZENET ABLAK
*/

$("#overlay").hide();
$("#loaderWindow").hide();

function showMessageWindow() {
	// $("#md-wrapper").addClass("blurred");
	$("#loaderWindow").hide();
	$("#msgWindow").show();
	$("#overlay").fadeIn(100);
}

function hideMessageWindow() {
	// $("#md-wrapper").addClass("blurred");
	$("#overlay").fadeOut(100);
}

function showLoaderWindow(overlay = false) {
	// $("#md-wrapper").addClass("blurred");
	$("#msgWindow").hide();
	$("#loaderWindow").show();
	if (overlay) {
		$("#overlay")
			.css("background-color", "rgba(white, 0.8)")
			.fadeIn(100);
	} else {
		$("#overlay")
			.css("background-color", "rgba(white, 0)")
			.fadeIn(100);
	}
}

function hideLoaderWindow() {
	// $("#md-wrapper").addClass("blurred");
	$("#overlay")
		.fadeOut(100)
		.css("background-color", "rgba(white, 0)");
	$("#loaderWindow").hide();
	// $("#msgWindow").show();
}

function msgWin(message, type) {
	$("#msgWindow span").html(message);
	$("#msgWindow")
		.removeClass("error")
		.removeClass("success");

	switch (type) {
		case "error":
			$("#msgWindow").addClass("error");
			break;

		case "success":
			$("#msgWindow").addClass("success");
			break;

		default:
			break;
	}
	showMessageWindow();
	setTimeout(() => {
		hideMessageWindow();
	}, 3000);
}

// ******************************************************Smooth scroll
function scrollToSection(section) {
	$("html, body").animate(
		{
			scrollTop: $(section).offset().top - 100
		},
		500
	);
}

$("#mdGallery").click(function() {
	scrollToSection("#galleryTitle");
});

$("#mdEditor").click(function() {
	scrollToSection("#editor");
});

/***********************************************************************************
//----------------------------------------------------------------------------------  GALÉRIA
*/

let filterCategory = "0";

function keress(kategoriaID) {
	$(".nincseredmeny").hide();
	if (filterCategory == "0") {
		$(".galleryItem").removeClass("blurred");
	} else {
		$(".galleryItem").addClass("blurred");
		$("[data-category='" + filterCategory + "']").removeClass("blurred");
	}

	let keresesiTalalatok = $(".galleryItem").not(".blurred").length;
	// console.log(keresettSzo);
	if (keresesiTalalatok == 0) {
		// msgWin("Nincs keresési találat", "error");
		$(".nincseredmeny").show();
	}
	console.log("Találatok: ", keresesiTalalatok);
	$(".talalatok span").html(keresesiTalalatok);
}

$("#focimText").on("keyup keydown change", ({ currentTarget }) => {
	let focimText = $(currentTarget).val(),
		padding = Math.round(15 * ratio),
		maxWidth = _canvas.width - (_mainBox.left + _mainBox.right),
		maxTextWidth = maxWidth - padding * 2,
		mainBoxWidth,
		textBoxWidth,
		fontSize = _textFocim.fontsize;

	textFocim.set({ text: focimText.toUpperCase(), fontSize: fontSize });
	canvas.renderAll();
	textBoxWidth = textFocim.getBoundingRect().width;

	while (textBoxWidth > maxTextWidth) {
		fontSize--;
		textFocim.set({
			text: focimText.toUpperCase(),
			fontSize: fontSize,
			top:
				_mainBox.top +
				(_mainBox.height - textFocim.getBoundingRect().height + 8) / 2
		});
		canvas.renderAll();
		save();
		textBoxWidth = textFocim.getBoundingRect().width;
	}

	mainBoxWidth = textBoxWidth + padding * 2;
	if (mainBoxWidth > maxWidth) mainBoxWidth = maxWidth;
	mainBoxRect.set({
		width: mainBoxWidth
	});
	canvas.renderAll();
	save();
});

$("#alcimText").on("keyup keydown change", ({ currentTarget }) => {
	let alcimText = $(currentTarget).val(),
		padding = 15,
		maxWidth = _canvas.width - (_mainBox.left + _mainBox.right),
		maxTextWidth = maxWidth - padding * 2,
		textBoxWidth,
		fontSize = _textAlcim.fontsize;

	textAlcim.set({ text: alcimText, fontSize: fontSize });
	canvas.renderAll();
	textBoxWidth = textAlcim.getBoundingRect().width;

	while (textBoxWidth > maxTextWidth) {
		fontSize--;
		textAlcim.set({
			text: alcimText,
			fontSize: fontSize
		});
		canvas.renderAll();
		save();
		textBoxWidth = textAlcim.getBoundingRect().width;
	}
});

var bothselects = $("#kategoria, #filterCategory");
bothselects.on("change", function({ currentTarget }) {
	bothselects.val(this.value); // "this" is the changed one

	filterCategory = $("option:selected", currentTarget).val();
	keress(filterCategory);

	let categoryColor = $("option:selected", currentTarget).data("color");

	let os = canvas.getObjects().map(function(o) {
		if (o.type == "rect") {
			o.set("fill", categoryColor);
		}
	});

	// mainBox.set("fill", categoryColor);
	setWorkColor(categoryColor);
	canvas.renderAll();
	save();
});

// ----------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------
$(document).ready(() => {
	$("#color-picker-container").hide();
	showLoaderWindow(true);

	let fonts = ["Open Sans", "CamptonBold"];

	Promise.all(
		// Preload fonts
		fonts.map(font => new FontFaceObserver(font).load())
	).then(() => {
		// Load page elements
		setAlcimTextColor("#ffffff");

		canvas
			.add(mainBoxRect)
			.add(textFocim)
			.add(textAlcim)
			.renderAll();
		save();

		hideLoaderWindow(true);

		filterCategory = $("option:selected", "#kategoria").val();
		keress(filterCategory);

		$(".talalatok span").html($(".galleryItem").not(".blurred").length);
	});
});
